import getConfig from 'next/config'

import { ApiConfiguration } from '@grandvisionhq/state'

const { publicRuntimeConfig } = getConfig()
const endpoints = publicRuntimeConfig.grandvision.endpoints ?? {}
const apiUrl = publicRuntimeConfig.grandvision.apiUrl ?? ''

export const getApiConfig = (): ApiConfiguration => ({
  apiUrl,
  cartsUrl: endpoints.carts,
  locationsUrl: endpoints.locations,
  ordersUrl: endpoints.orders,
  paymentsUrl: endpoints.payments,
  productsUrl: endpoints.products,
  storesUrl: endpoints.stores,
  subscriptionsUrl: endpoints.subscriptions,
})

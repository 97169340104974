import type { Config } from '@grandvisionhq/shipping'

export const getDeliveryIndicatorConfig = (): Config => ({
  inventoryChannels: {
    'f26fa401-020a-467e-8d84-b0166ea83dbf': {
      // online-store TEST
      expectedDeliveryDays: {
        onStock: '1-3',
        outOfStock: '8-10',
      },
    },
    'f7e48030-6915-4ab8-8661-e197641a75ff': {
      // online-store ACCT
      expectedDeliveryDays: {
        onStock: '1-3',
        outOfStock: '8-10',
      },
    },
    'c1ec6a05-6663-4cdd-b9eb-ccb173742a71': {
      // online-store PROD
      expectedDeliveryDays: {
        onStock: '1-3',
        outOfStock: '8-10',
      },
    },
  },
  missingInventoryChannelStrategy: {
    expectedDeliveryDays: '8+',
  },
  nextDayDeliveryTimeLimit: '18:00',
  productTypeDeliveryConfig: [
    {
      type: 'GLASSES_BUNDLE',
      expectedDeliveryDays: '7-10',
    },
    {
      type: 'NON_PRESCRIPTION_BUNDLE',
      expectedDeliveryDays: '5',
    },
    {
      type: 'CONTACT_LENSES',
      expectedDeliveryDays: '3',
    },
    {
      type: 'REGULAR',
      expectedDeliveryDays: '1',
    },
  ],
})

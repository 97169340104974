import type { TimeFilterConfiguration } from '@grandvisionhq/appointments'

const config: TimeFilterConfiguration = [
  {
    name: 'option1',
    from: '08:00',
    until: '12:00',
  },
  {
    name: 'option2',
    from: '12:00',
    until: '16:00',
  },
  {
    name: 'option3',
    from: '16:00',
    until: '20:00',
  },
]

export const getTimeFiltersConfig = () => config

import getConfig from 'next/config'

import { GoogleMapsSettings, Pin } from '@grandvisionhq/common'
import { Components } from '@grandvisionhq/stores-v2'

const { publicRuntimeConfig } = getConfig()
const gmKey = publicRuntimeConfig.googleMaps.apiKey ?? ''
const gmCenter = publicRuntimeConfig.googleMaps.mapCenter ?? {}

const { PreferredStorePin } = Components

const pinThemes = {
  primary: { foreground: '#ffffff', background: '#9E007E' },
  highlight: { foreground: '#ffffff', background: '#3F0032' },
  hovered: { foreground: '#6F0058', background: '#ffffff' },
}

export const getGoogleMapsConfig = (): GoogleMapsSettings => ({
  apiKey: gmKey,
  defaultCenter: gmCenter,
  defaultZoom: 6,
  pin: Pin({
    themes: pinThemes,
  }),
  preferredStorePin: PreferredStorePin({
    themes: pinThemes,
  }),
  // markerClusterOptions: MarkerClusters({
  //   theme: {
  //     color: '#9E007E',
  //     textColor: '#ffffff',
  //   },
  //   minimumClusterSize: 3,
  //   maxZoom: 10,
  // }),
})

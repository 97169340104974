import { Countries, Currencies, DistanceUnit, IntlSettings, Locales } from '@grandvisionhq/state'

export const INTL_DEFAULTS: IntlSettings = {
  country: Countries.GB,
  locale: Locales['en-GB'],
  currency: Currencies.GBP,
  distanceUnit: DistanceUnit.mile,
}

type PossibleLocales = keyof Pick<typeof Locales, 'en-GB'>

export const intlConfigMap: {
  [L in PossibleLocales]: IntlSettings
} = {
  'en-GB': INTL_DEFAULTS,
}
